import { graphql, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { DiscountSection } from '../components/discount'
import { FAQ2 } from '../components/faq2'
import { FoodCarouselSection3 } from '../components/food3'
import { HowItWork2 } from '../components/howitwork-v2'
import { Loader } from '../components/loader'
import { MenuOrderInfo } from '../components/menuOrderInfo'
import { RedirectModal } from '../components/redirect-modal'
import { SaleFormModal2 } from '../components/sale-form-modal2'
import SEO from '../components/seo'
import { Reviews2 } from '../components/reviews2'
import { Hero3New } from '../components/hero3-new/hero3-new'
import { FoodCarouselNovinky } from '../components/food-novinky'
import { WeDeliverInPolish } from '../components/weDeliverInPolish'
import { Quality } from '../components/quality'
import { NutritionistSection } from '../components/nutritionist'
import { NewCalcBlocks } from '../components/new-calculate-blocks'

const ScrollToSection = () => {
  useEffect(() => {
    scrollToHash()
  }, [])

  const scrollToHash = () => {
    if (typeof window !== 'undefined') {
      const hash = window.location.hash
      if (hash) {
        const element = document.querySelector(hash)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' })
        }
      }
    }
  }

  return <></>
}

const NewHomepage = () => (
  <>
    <SEO title="Home" />
    <ScrollToSection />
    <RedirectModal />
    <SaleFormModal2 formName="Akce 2 dny" />
    <Hero3New />
    <MenuOrderInfo />
    <div id="calculator2" />
    {/* <Order2 id="calculator" /> */}
    <NewCalcBlocks id="calculator" />
    <FoodCarouselNovinky />
    <HowItWork2 />
    <FoodCarouselSection3 id="food-section" />
    <WeDeliverInPolish />
    <NutritionistSection />
    {/* <Quality /> */}
    <Reviews2 id="reviews" />
    {/* <DiscountSection /> */}
    <FAQ2 />
  </>
)



const WyprobujMenu = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
              section
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    window.localStorage.removeItem('deliveryPrice') // necessary for order form
  }, [])

  return (
    <>
      {/* {pageVersion === "current-version" ? (
        <OldHomepage site={site} />
      ) : pageVersion === "new-version" ? (
        <NewHomepage site={site} />
      ) : (
        <Loader />
      )} */}
      {site ? (
        <NewHomepage site={site} />
      ) : (
        <Loader />
      )}
    </>
  )
}

export default WyprobujMenu
